let auth0Client = null;
const fetchAuthConfig = () => fetch("/auth_config.json");

const configureClient = async () => {
  const response = await fetchAuthConfig();
  const config = await response.json();

  auth0Client = await auth0.createAuth0Client({
    domain: config.domain,
    clientId: config.clientId
  });
};

window.onload = async () => {
  console.log("window.onload")
  await configureClient();
  const query = window.location.search;

  // NEW - update the UI state
  updateUI();

  // NEW - check for the code and state parameters
  if (query.includes("code=") && query.includes("state=")) {

    // Process the login state
    console.log("Process the login state")
    await auth0Client.handleRedirectCallback();

    updateUI();

    // Use replaceState to redirect the user away and remove the querystring parameters
    window.history.replaceState({}, document.title, "/");
  } else if (query.includes("error=")) {
    console.log("error")
    alert("Access Denied");
    // NEW - If the user clicked the logout button, redirect to the home page
    logout();
    return;
  }
  const isAuthenticated = await auth0Client.isAuthenticated();
  if (!isAuthenticated) {
    login();
  }

}

// NEW
const updateUI = async () => {
  const isAuthenticated = await auth0Client.isAuthenticated();

  document.getElementById("btn-logout").disabled = !isAuthenticated;
  document.getElementById("btn-login").disabled = isAuthenticated;
  if (isAuthenticated) {
    document.getElementById('loader').style.display = 'flex';
    fetchData().then((data) => {
      // Replace "summaryTable" with the fetched "data" in the renderTable function or other relevant functions
      renderBasicTable(data, "table");
    });
  }
};

async function login() {
  console.log("Attempting login", window.location.origin)
  await auth0Client.loginWithRedirect({
    authorizationParams: {
      redirect_uri: window.location.origin
    }
  });
};

const logout = async () => {
  await auth0Client.logout({
    logoutParams: {
      returnTo: window.location.origin
    }
  });
};

const renderBasicTable = (data, divId) => {
  var tableHtml = '<table id="myTable" class="display">';
  tableHtml += `
    <thead><tr>
    <th>Project</th>
    <th>Domain</th>
    <th>Action</th>
    <th>Platform</th>
    <th>Pages Visited</th>
    <th><div class="rotated-th"><span class="rotated-th__label rotated_custom">Count Information Sharing</div></span></th>
    <th><div class="rotated-th"><span class="rotated-th__label rotated_custom">Is Cuser Id Tracked</div></span></th>
    <th><div class="rotated-th"><span class="rotated-th__label rotated_custom">Button Click Tracking</div></span></th>
    <th><div class="rotated-th"><span class="rotated-th__label rotated_custom">Page Visited Tracking</div></span></th>
    <th><div class="rotated-th"><span class="rotated-th__label rotated_custom">Filtered Sensitive Params</div></span></th>
    <th><div class="rotated-th"><span class="rotated-th__label rotated_custom">Filter Tracking</div></span></th>
    <th><div class="rotated-th"><span class="rotated-th__label rotated_custom">Search Term Tracking</div></span></th>
    <th><div class="rotated-th"><span class="rotated-th__label rotated_custom">State</div></span></th>
    <th><div class="rotated-th"><span class="rotated-th__label rotated_custom">Website Monthly Visits</div></span></th>
    <th>Pixel Removed</th>
      </tr></thead>`;

  // <th>Visited Url</th>
  // <th>Project</th>
  // <th>Stub</th>
  tableHtml += '<tbody>';
  const tdStyle = 'style="border-top: 1px solid #ddd;max-width: 250px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;min-width: 50px;text-align: center;"'
  for (var i = 0; i < data.length; i++) {
    if (data[i].removedTracking) {
      console.log("Tracking removed")
    }
    tableHtml += '<tr>';
    tableHtml += '<td ' + tdStyle + '>' + data[i].projectName + '</td>';
    tableHtml += '<td ' + tdStyle + '><a href="' + `/q?projectId=${data[i].projectId}&domain=${encodeURIComponent(data[i].domain)}">${data[i].domain}</a></td>`;
    tableHtml += '<td ' + tdStyle + '><a href="' + `/q?projectId=${data[i].projectId}&domain=${encodeURIComponent(data[i].domain)}&harFilename=${encodeURIComponent(data[i].harFilename)}">${data[i].harFilename}</a></td>`;
    tableHtml += '<td ' + tdStyle + '>' + (data[i].platform || 'Web Default') + '</td>';
    // tableHtml += '<td>' + data[i].visited_url + '</td>';
    // Generate bullet list for visited_url column
    // console.log(data[i].visitedUrls)
    if (data[i].visitedUrls) {
      var visitedUrlList = JSON.parse(data[i].visitedUrls);
      var visitedUrlHtml = '<ul>';
      visitedUrlHtml += visitedUrlList.map(function (url) {
        return '<li>' + url + '</li>';
      }).join('');
      visitedUrlHtml += '</ul>';
      tableHtml += '<td ' + tdStyle + '>' + visitedUrlHtml + '</td>';
    } else {
      tableHtml += '<td></td>';
    }
    tableHtml += '<td ' + tdStyle + '>' + data[i].countInformationSharing + '</td>';
    tableHtml += '<td ' + tdStyle + ' class="' + (data[i].isCuserIdTracked == 'Yes' ? 'yes' : '') + '">' + data[i].isCuserIdTracked + '</td>';
    tableHtml += '<td ' + tdStyle + ' class="' + (data[i].buttonClickTracking == 'Yes' ? 'yes' : '') + '">' + data[i].buttonClickTracking + '</td>';
    tableHtml += '<td ' + tdStyle + ' class="' + (data[i].pageVisitedTracking == 'Yes' ? 'yes' : '') + '">' + data[i].pageVisitedTracking + '</td>';
    tableHtml += '<td ' + tdStyle + ' class="' + (data[i].filteredSensitiveParams == 'Yes' ? 'yes' : '') + '">' + data[i].filteredSensitiveParams + '</td>';
    tableHtml += '<td ' + tdStyle + ' class="' + (data[i].filterTracking == 'Yes' ? 'yes' : '') + '">' + data[i].filterTracking + '</td>';
    tableHtml += '<td ' + tdStyle + ' class="' + (data[i].searchTermTracking == 'Yes' ? 'yes' : '') + '">' + data[i].searchTermTracking + '</td>';
    tableHtml += '<td ' + tdStyle + '>' + (data[i].state || '') + '</td>';
    tableHtml += '<td ' + tdStyle + '>' + (data[i].websiteMonthlyVisits ? data[i].websiteMonthlyVisits.toLocaleString('en-US') : '') + '</td>';
    tableHtml += '<td ' + tdStyle + ' class="' + (data[i].projectName === "Hospitals" ? (data[i].removedTracking ? "yes" : "") : "") + '" >' + (data[i].projectName === "Hospitals" ? (data[i].removedTracking ? "Removed" : "Not Removed") : "") + '</td>';
    // tableHtml += '<td ' + tdStyle + '>' + (data[i].employees || '') + '</td>';
    tableHtml += '</tr>';
  }
  tableHtml += '</tbody></table>';

  $('#' + divId).html(tableHtml);

  var table = $('#myTable').DataTable({
    order: [[11, 'dsc']], // sort by the first column (domain) in ascending order
    rowGroup: {
      dataSrc: [0, 1]
    }, // group rows by the first column (index 0)
    dom: 'lfrtBip',
    searching: true,
    select: [],
    ordering: true,
    paging: true,
    info: true,
    lengthMenu: [10, 25, 50, 100],
    pageLength: 100,
  });

  [0, 3, 12].forEach(function (columnIndex) {
    // Get unique values from the column and add them to the select
    table.column(columnIndex).data().unique().sort().each(function (value, index) {
      $('#column' + (columnIndex + 1) + '_filter').append('<option value="' + value + '">' + value + '</option>');
    });

    // Filter the table when the select value changes
    $('#column' + (columnIndex + 1) + '_filter').on('change', function () {
      var val = $.fn.dataTable.util.escapeRegex(
        $(this).val()
      );
      table.column(columnIndex).search(val ? '^' + val + '$' : '', true, false).draw();
    });
  });

  var tableHeaders = document.querySelectorAll('#myTable th');
  for (var i = 0; i < tableHeaders.length; i++) {
    if (i === 0) {
      tableHeaders[i].style.width = '10%';
    } else if (i === 1) {
      tableHeaders[i].style.width = '10%';
    } else if (i === 2) {
      tableHeaders[i].style.width = '15%';
      // tableHeaders[i].style.width = '25%';
    }
    // Add widths for other columns as needed
  }
  document.getElementById('loader').style.display = 'none';
}

async function fetchData() {
  try {
    const response = await fetch(`/api/data`);
    console.log(response)
    if (!response.ok) {
      throw new Error('An error occurred while fetching data.');
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
}